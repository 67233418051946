html {
  background-color: #404040;
  margin: 0;
  color: #ff6622;
}

body {
  margin: 0 auto;
  max-width: 600px;
}
